import React, {
	Component,
	useRef,
	useEffect,
	useState
} from 'react';

import Cookies from 'universal-cookie';
import Icon from 'components/Icon';
import lokalise from 'utils/lokalise';
import MenuHelper from 'utils/menu';
import config from 'config';

const UniversalCookie = new Cookies();

class LocationTitle extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			screen,
			address,
			toggleLocation,
			menuType,
			menuOpen,
			locationInput,
			handleChange,
			appLocation,
		} = this.props;

		return (
			<div className="LocationTitle">
				{menuType === 'anywhere' ? <LocationInput menuOpen={menuOpen} handleChange={handleChange} locationInput={locationInput} /> : null}
				<StaticLocationName menuType={menuType} menuOpen={menuOpen} address={address} screen={screen} appLocation={appLocation} />
			</div>
		);
	};
}

/*
 * We treat text input as throwaway and dont store state, it merely is sent to the search results
 */
function LocationInput(props) {
	const {
		menuOpen,
		handleChange,
	} = props;

	const textInput = useRef(null);

	useEffect(() => {
		if (textInput.current) {
			if (menuOpen) {
				// This doesnt work for Mobile Safari, see original click action in
				textInput.current.focus();
			} else {
				textInput.current.value = '';
				textInput.current.blur();
			}
		}
	});

	return (
		<input
			role="search"
			style={{ display: menuOpen ? 'block' : 'none' }}
			ref={textInput}
			className="LocationInput"
			//The word placemark doesn't get translated in lokalize
			placeholder={lokalise.getKey("ts12l")}
			type="text"
			id="SearchInput"
			onChange={handleChange}
		/>
	);
}

function StaticLocationName(props) {
	const {
		screen,
		address,
		appLocation,
		menuOpen,
		menuType,
	} = props;

	const [title, setTitle] = useState('');

	useEffect(() => {
		if (appLocation && appLocation.name) {
			setTitle(appLocation.name);
		} else if (address) {
			// Anywhere geocode
			setTitle(address);
		} else if (screen) {
			const {
				prettyName,
				address
			} = screen;
			setTitle(prettyName ? prettyName : address);
		} else if (appLocation && appLocation.coords) {
			// Look in savedPlaces first
			let savedPlaces = UniversalCookie.get(config.cookies.savedPlaces.name);
			let place = savedPlaces?.find((saved) => saved.coords.latitude === appLocation.coords.latitude && saved.coords.longitude === appLocation.coords.longitude);
			if (place) {
				setTitle(place.name);
			} else {
				savedPlaces = [];
				const latlng = {
					lat: appLocation.coords.latitude,
					lng: appLocation.coords.longitude,
				};
				let resp = MenuHelper.reverseGeocode(latlng);
				if (resp) {
					resp.then(function(r) {
						setTitle(r.formatted_address);
						place = {
							name: r.formatted_address,
							address: r.formatted_address,
							type: "place",
							// These are the coords that came through the API response
							responseCoords: {
								latitude: r.geometry.location.lat().toFixed(6),
								longitude: r.geometry.location.lng().toFixed(6)
							},
							// This is the current app locatino
							coords: {
								latitude: appLocation.coords.latitude,
								longitude: appLocation.coords.longitude,
							}
						};
						savedPlaces.push(place);
						UniversalCookie.set(config.cookies.savedPlaces.name, savedPlaces, {
							secure: true,
							sameSite: 'none',
							path: '/',
							expires: config.cookies.savedPlaces.expires,
						});
						setTitle(place.name);
					});
				}
			}
		} else {
			let coords = appLocation && appLocation.coords ? `${appLocation.coords.latitude.toFixed(5)}, ${appLocation.coords.longitude.toFixed(5)}` : '';
			setTitle(`${coords}`);
		}

	}, [appLocation, address, screen]);

	const style = { display: (menuOpen && menuType === 'anywhere') ? 'none' : 'block' };

	const voTitle = title;
	const voiceoverTitleText = `Showing transportation choices at ${voTitle}, double tap to open location search`;

	return (
		<div className="StaticLocationName" style={style}>
			<span className="voiceover-text voiceover-fill">
				<h1 style={{ fontSize: '2em' }}>{voiceoverTitleText}</h1>
			</span>
			<h1 aria-hidden="true" className="heading">
				<Icon className="location-icon" category="ui" name="mapLocation" />
				<span aria-hidden="true">
					<span className="location-name">{title}</span>
				</span>
			</h1>
		</div>
	);
}

export default LocationTitle;
